import React from "react"
import Reusable from "../../components/articleLayout"
import TWTLogo from "../../images/twt.png"
import CoinbaseCard from "../../images/TwitterCards/BTCCard.png"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const TrustWallet125 = () => (
  <Layout>
    <SEO
      title="Earn 125+ TWT with Trust Wallet"
      description="Learn how to earn 125+ TWT with Trust Wallet."
      article={true}
      image={CoinbaseCard}
      pathname="/crypto/trustwallet-twt-125"
    />
    <Reusable
      CoinbaseRef={false}
      Logo={TWTLogo}
      ImageAlt="TWT Logo"
      From="www.trustwallet.com"
      Title="Earn 125+ TWT with Trust Wallet"
      Ticker="TWT"
      Value="125+"
      Address="https://share.trustwallet.com/2IKkd1"
      Step1="Click the “Claim Now” button on this page."
      Step2="You will be redirected to Coinbase, with my invitation to join."
      Step3="Fill out your information and click Create Account."
      Step4="Check your email and verify your email address."
      Step5="Verify your identity. (KYC “Know Your Customer” is standard practice for crypto investing.)"
      Step6="Enter your phone number to complete 2-step verification. (This is also a standard in the crypto space to protect your account.)"
      Step7="Add a payment method. (Bank Account or Debit Card.)"
      Step8="Purchase at least $100 of crypto."
      Step9="Congratulations! You will receive $10 USD."
      Introduction="If you are new to crypto and are thinking about investing, Coinbase is by far the easiest way to get started! As being the world’s largest on ramp to the world of crypto, Coinbase currently offers 20 of the most popular cryptocurrencies to choose from. You can purchase these coins using your Bank Account or Debit Card within minutes on signing up. Coinbase also offers an earn program where you can earn up to $186 in crypto for watching videos and taking short quizzes. (See my other tutorials to learn more.) If you sign up using my invitation link, we both will receive $10 USD for free, once you buy or sell more than $100 in crypto."
    />
  </Layout>
)

export default TrustWallet125
